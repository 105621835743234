var formReserve = {
    
    init: function () {
        
        var $closeForm = $('#close-form');

        $closeForm.on('click', function() {
            // console.log('open form');
            $('.check').toggleClass('desplegado');
        })
          
        var sectionIds = $('.check');
        
        $(window).scroll( function(){

            sectionIds.each( function(){ 
          
                var container = $('.live');
                var containerOffset = $(container).offset().top;
                var scrollPosition = $(window).scrollTop();
                
                if(scrollPosition > containerOffset) {
                    $(this).addClass('inactive');
                } else {
                    $(this).removeClass('inactive');
                }    
            });
        });        
	}
};

var reserve = {
	
	init: function () {

		(function($) {

			$("#iframe_dates").html('<iframe width="100%" height="1200" frameborder="0" src="https://booking.roomcloud.net/be/se2/hotel.jsp'+window.location.search+'"></iframe>');

			// iFrameResize({
			// 	checkOrigin: false,
			// 	enablePublicMethods: true,
			// 	bodyBackground: 'transparent'
			// });
			
		})(jQuery);
	}
};

var formContact = {
	
	init: function () {

        $("#destino").change(function() {
           
            switch($(this).val()) {
                case 'Iguazu':
                    $("#motivo").html("<option value='iguazu@loisuites.com.ar'>Servicios</option><option value='reservas4@loisuites.com.ar'>Tarifas y reservas</option><option value='rrhh_iguazu@loisuites.com.ar'>Trabaja con nosotros</option><option value='promociones@loisuites.com.ar'>Promociones</option>");
                    break;
                case 'Recoleta':
                    $("#motivo").html("<option value='recoleta@loisuites.com.ar'>Servicios</option><option value='reservas@loisuites.com.ar'>Tarifas y reservas</option><option value='rrhh@loisuites.com.ar'>Trabaja con nosotros</option><option value='promociones@loisuites.com.ar'>Promociones</option>");
                    break;
                case 'Chapelco':
                    $("#motivo").html("<option value='chapelco@loisuites.com.ar'>Servicios</option><option value='reservas1@loisuites.com.ar'>Tarifas y reservas</option><option value='rrhh_chapelco@loisuites.com.ar'>Trabaja con nosotros</option><option value='promociones@loisuites.com.ar'>Promociones</option>");
                    break;
                case 'Esmeralda':
                    $("#motivo").html("<option value='esmeralda@loisuites.com.ar'>Servicios</option><option value='reservas3@loisuites.com.ar'>Tarifas y reservas</option><option value='rrhh@loisuites.com.ar'>Trabaja con nosotros</option><option value='promociones@loisuites.com.ar'>Promociones</option>");
                    break;
                default:
                    $("#motivo").html("<option value=''>Motivo de la consulta:</option>");
            }            
        });
	}
};

var formPromo = {
	
	init: function () {

		// (function($) {

            $("#destino").change(function() {
           
                switch($(this).val()) {
                    case 'Iguazu':
                        $("#promo").html("<option value='Navidad'>Navidad</option><option value='Fin de año'>Fin de año</option><option value='Noche de bodas'>Noche de bodas</option><option value='Noche de romántica'>Noche de romántica</option><option value='Cena romántica'>Cena romántica</option><option value='Merienda en la selva'>Merienda en la selva</option><option value='Promo residentes'>Promo residentes</option>");
                        break;
                    case 'Recoleta':
                        $("#promo").html("<option value='Navidad'>Navidad</option><option value='Fin de año'>Fin de año</option><option value='Escapada relax'>Escapada relax</option><option value='Escapada foodie'>Escapada foodie</option><option value='Noche de bodas'>Noche de bodas</option><option value='Té jardín'>Té jardín</option>");
                        break;
                    case 'Chapelco':
                        $("#promo").html("<option value='Navidad'>Navidad</option><option value='Fin de año'>Fin de año</option><option value='Promo residentes'>Promo residentes</option><option value='Noche de bodas'>Noche de bodas</option><option value='Merienda '>Merienda </option>");
                        break;
                    default:
                        $("#promo").html("<option value=''>Elija una promoción</option>");
                }            
            });
			
		// })(jQuery);
	}
};

reserve.init();
formReserve.init();
formContact.init();
formPromo.init(); 