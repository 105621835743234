var swipe = {
	init: function () {
		
		var swiperEventsHome = new Swiper('.swiper.slide-events-home', {
			loop: true,
			autoplay: {
				delay: 3500,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			slidesPerView: 1, 
		}); 

		var galleryLFlats = new Swiper('.swiper-container.gallery_lflats', {
			loop: true,
			autoplay: {
				delay: 3500,
			}, 
			lazy: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
			spaceBetween: 0,
			// Responsive breakpoints
			breakpointsInverse: true,
			breakpoints: {
				// when window width is >= 1024px
				768: {
					slidesPerView: 3,
					spaceBetween: 10
				},
				1280: {
					slidesPerView: 4,
					spaceBetween: 10
				}
			}
		});		


		var swiperLivein = new Swiper('.swiper.live-in_slide', {
			lazy: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			slidesPerView: 1,
		});

		var galleryPictures = new Swiper('.swiper-container.gallery_pictures', {
			loop: true,
			autoplay: {
				delay: 3500,
			}, 
			lazy: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
			spaceBetween: 0,
			// Responsive breakpoints
			breakpointsInverse: true,
			breakpoints: {
				// when window width is >= 1024px
				1024: {
					slidesPerView: "auto",
				  	spaceBetween: 20,
					centeredSlides: true,
				}
			}
		});		

		var galleryPicturesRooms = new Swiper('.swiper-container.rooms_gallery_pictures', {
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 3000,
			},
			slidesPerView: 1,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
		});		

		var galleryThumbs = new Swiper('.swiper-container.rooms_gallery_thumbs', {
			centerInsufficientSlides: true,
			slidesPerView: 2,
			breakpointsInverse: true,
			breakpoints: {
				768: {
					slidesPerView: 4
				  },
				// when window width is >= 1024px
				1200: {
				  slidesPerView: 5
				},
			},
			// freeMode: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
		});		

		var swiperHotelGallery = new Swiper('.swiper-container.rooms_gallery_slide', {
			loop: true,
			autoHeight: true,
			fadeEffect: { crossFade: true },
			virtualTranslate: true,
			autoplay: {
				delay: 6000,
			},
			thumbs: {
				swiper: galleryThumbs
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
			effect: "fade",
		});

		var swiperEventsGallery1 = new Swiper('.swiper-container.events-01', {
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 3000,
			},
			slidesPerView: 1,			
		});

		var swiperEventsGallery2 = new Swiper('.swiper-container.events-02', {
			// loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 3000,
			},
			slidesPerView: 1,			
		});

		var swiperEventsGallery3 = new Swiper('.swiper-container.events-03', {
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 3000,
			},
			slidesPerView: 1,			
		});

		var swiperEventsGallery4 = new Swiper('.swiper-container.events-04', {
			// loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 3000,
			},
			slidesPerView: 1,			
		});

		var swiperEventsGallery5 = new Swiper('.swiper-container.events-05', {
			// loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 3000,
			},
			slidesPerView: 1,			
		});

		var swiperPromo01 = new Swiper('.swiper-container.promo-01', {
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 5000,
			},
			slidesPerView: 1,			
			spaceBetween: 30,
		});

		var swiperPromo02 = new Swiper('.swiper-container.promo-02', {
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 5000,
			},
			slidesPerView: 1,			
			spaceBetween: 30,
		});

		var swiperPromo03 = new Swiper('.swiper-container.promo-03', {
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 5000,
			},
			slidesPerView: 1,			
			spaceBetween: 30,
		});

		var swiperPromo04 = new Swiper('.swiper-container.promo-04', {
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 5000,
			},
			slidesPerView: 1,			
			spaceBetween: 30,
		});

	}
};

swipe.init();