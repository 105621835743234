//appends an "active" class to .popup and .popup-content when the "Open" button is clicked
$("#sustentabilidad").on("click", function() {
  $(".popup-overlay").addClass("active");
  $("body").addClass("active");

});

//removes the "active" class to .popup and .popup-content when the "Close" button is clicked 
$("#modal").on("click", function() {
  $(".popup-overlay").removeClass("active");
  $("body").removeClass("active");
});