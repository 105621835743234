var scrollMenu = {
	init: function () {

		var $menuScoll = $('.go-to'); 

		$menuScoll.on('click', function() {
		    // On-page links
			if (
				location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
				&& 
				location.hostname == this.hostname
			) {
				// Figure out element to scroll to
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				event.preventDefault();
				if (target.length) {
				var targetOffset = target.offset().top - 58;
					// Only prevent default if animation is actually gonna happen
					$('html, body').animate({
						scrollTop: targetOffset
					}, 400, function() {
						// Callback after animation
						// Must change focus!
						var $target = $(target);
						$target.focus();
						if ($target.is(":focus")) { // Checking if the target was focused
							return false;
						} else {
						$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
						$target.focus(); // Set focus again
						}
					});
				}
			}
		});		
	}
};

scrollMenu.init();